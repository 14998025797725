// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-calendar-vector {
  display: inline-flex;
  position: relative;
  height: 50%;
}

.button-vector {
  background: #fe7750;
  border: 1px solid #fe7750;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EAEI,oBAAA;EACA,kBAAA;EACA,WAAA;AACJ;;AAEA;EACI,mBAAA;EACA,yBAAA;AACJ","sourcesContent":[".p-calendar-vector {\n    display: -ms-inline-flexbox;\n    display: inline-flex;\n    position: relative;\n    height: 50%;\n}\n\n.button-vector {\n    background: #fe7750;\n    border: 1px solid #fe7750;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
