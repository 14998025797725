import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';

import { MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { OAuthCallbackComponent } from './login/oauth-callback.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { httpInterceptorProviders } from './core/providers/interceptors';

export function httpTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		OAuthCallbackComponent,
		LoaderComponent
	],
	imports: [
		FormsModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		TranslateModule.forRoot({
		loader: {
			provide: TranslateLoader,
			useFactory: httpTranslateLoader,
			deps: [HttpClient]
		}
	}),
		CalendarModule,
		DialogModule,
		DropdownModule,
		MenubarModule,
		MessageModule,
		PanelModule,
		SelectButtonModule,
		SidebarModule,
		SplitButtonModule,
		InputMaskModule,
		ChipsModule,
		InputTextModule,
		BreadcrumbModule,
		PanelModule,
		ToolbarModule,
		ToastModule,
		ProgressSpinnerModule
	],
	providers: [
		httpInterceptorProviders, CookieService, MessageService
	],
	bootstrap: [AppComponent]
})

export class AppModule {

}
