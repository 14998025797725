import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from '../service/loader.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit, OnDestroy {
	isShowLoader = false;
	private subscription: Subscription;

	constructor(private loaderService: LoaderService) {}

	ngOnInit(): void {
		this.subscription = this.loaderService.loaderState.subscribe(
			(state) => {
				this.isShowLoader = state;
			}
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
