import { Component, OnInit, Inject } from '@angular/core';
import {DOCUMENT} from '@angular/common';

import { environment } from '../core/environments/environment';

@Component({
	template: `
		<h3>Redirecting to login server</h3>
  	`
})

export class LoginComponent implements OnInit {


	/** the configured URL endpoint to send the user's browser to for token negotiation */
	_endPoint: string;
	/** reference to the window object */
	_window: Window;

	/** Creates a new instance of the LoginComponent, makes available
	 * a DOCUMENT reference and instantiates required variables
	 *
	 * @param document angular provided reference to the current document
	 */
	constructor(@Inject(DOCUMENT) private document: Document) {
		this._window = this.document.defaultView;
		this._endPoint = environment.openidUrl +
			'?client_id=' + environment.openidClientId +
			'&resource=' + environment.openidResource +
			'&response_type=token+id_token' +
			'&redirect_uri=' + encodeURIComponent(environment.openidRedirectUrl);

	}

	/** once the component is up and running, redirect the user to the auth provider */
	ngOnInit() {
		this._window.location.replace(this._endPoint);
	}

}
