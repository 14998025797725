import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '../../shared/components/service/loader.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class LoaderInterceptorService implements HttpInterceptor {
	constructor(private loaderService: LoaderService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		this.loaderService.show();
		return next
			.handle(req)
			.pipe(
				catchError((err) => {
					this.loaderService.hide();
					return throwError(() => err);
				})
			)
			.pipe(
				map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
					if (evt instanceof HttpResponse) {
						this.loaderService.hide();
					}
					return evt;
				})
			);
	}
}
