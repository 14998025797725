import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {

	constructor() {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let authReq = request;
		const token = sessionStorage.getItem('token');
		if (token != null) {
			authReq = request.clone({
				setHeaders: {
					'Authorization': `Bearer ${token}`
				},
			});
		}

		return next.handle(authReq);
	}
}


