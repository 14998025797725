import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent  {
	translate: TranslateService;

	constructor(
		public translateService: TranslateService,
		public cookie: CookieService
	) {

		this.translate = translateService;

		this.translate.setDefaultLang('pt');

		if (this.cookie.get('language')) {
			this.translate.use(this.cookie.get('language'));
		} else {
			this.translate.use('pt');
			this.cookie.set('language', 'pt');
		}
	}
}

