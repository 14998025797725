import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerInterceptorService implements HttpInterceptor {
	constructor(
		private messageService: MessageService,
		private router: Router
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(catchError(err => {
			console.log(err);

			switch (err.status) {
				// Handle errors that the api does not return (likely connection errors)
				case 0:
					this.messageService.add({
						severity: 'error',
						summary: 'Connection Failed',
						detail: err.message,
						life: 3500,
						closable: false
					});
					break;
				case 400:
					this.handle400Error(err);
					break;
				case 401:
				case 403:
					this.router.navigate(['/denied']);
					break;
				case 404:
					this.handle404Error(err);
					break;
				default:
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail: this.concatenateStrings(err.error.error.messages),
						life: 3500,
						closable: false
					});
			}
			return throwError(() => err);
		}));
	}

	// TODO REFATORAR
	private concatenateStrings(messages: string[]): string {
		return messages.join('\n');
	}

	private handle400Error(err) {
		// Some error 400 returns dataErrors others don't motive unknown
		if (err.error.error.dataErrors && err.error.error.dataErrors.length !== 0) {
			this.showDataErrors(err.error.error.dataErrors);
		} else {
			this.showMessageInDataErrors(err.error.error.messages);
		}
	}

	private handle404Error(err) {
		// This message is generated by BFF on ExceptionHandlers
		// If changed here, consider changing in all ExceptionHandlers
		if (err.error.error.messages[0] !== 'Not Found') {
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: this.concatenateStrings(err.error.error.messages),
				life: 3500,
				closable: false
			});
		}
	}

	private showMessageInDataErrors(messages: string[]) {
		const allMessagesString = messages.join(', ');
		const allMessagesList = allMessagesString.split(', ');

		allMessagesList.forEach(message => {
			// Commenting because this format doesn't work with BFF's ErrorResponse
			// const parameter = message.split(':')[0].split('.')[1]?.trim();
			// let problem = message.split(':')[1]?.trim();

			// // Avoid printing long messages, normally pattern
			// if (problem?.match('must match') && problem?.length >= 50) {
			// 	console.log(`Complete Error: ${parameter}: ${problem}`);
			// 	problem = 'must match pattern';
			// }

			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: message,
				life: 3500,
				closable: false
			});
		});
	}

	private showDataErrors(dataErrors: any[]) {
		dataErrors.forEach(dataError => {
			// Avoid printing long messages, normally pattern
			if (dataError.message.match('must match') && dataError.message.length >= 50) {
				console.log(`Complete Error: ${dataError.name}: ${dataError.message}`);
				dataError.message = 'must match pattern';
			}

			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: `${dataError.name}: ${dataError.message}`,
				life: 3500,
				closable: false
			});
		});
	}
}
