import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoaderService {
	private loaderSubject = new Subject<boolean>();
	private loading = false;

	get isLoading(): boolean {
		return this.loading;
	}

	loaderState = this.loaderSubject.asObservable();

	constructor() {}


	show(): void {
		this.loaderSubject.next(true);
		this.loading = true;
	}

	hide(): void {
		this.loaderSubject.next(false);
		this.loading = false;
	}
}
