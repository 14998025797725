// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.loading-container {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #343a40;
  z-index: 9999;
  opacity: 0.4;
  padding: 20%;
 }
`, "",{"version":3,"sources":["webpack://./src/app/shared/components/loader/loader.component.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,YAAY;CACb","sourcesContent":["\n.loading-container {\n  display: flex;\n  justify-content: center;\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  background-color: #343a40;\n  z-index: 9999;\n  opacity: 0.4;\n  padding: 20%;\n }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
