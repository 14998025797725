import { Component, OnInit } from '@angular/core';

@Component({
	template: `
		<h3>Redirecting to requested URL</h3>
	`
})
export class OAuthCallbackComponent implements OnInit {

	user: string;
	country: string;

	constructor() { }

	ngOnInit() {
		const callbackResponse = (document.URL).split('#')[1];

		const params = new URLSearchParams(callbackResponse)


		if (params.get('id_token') != null) {
			const accessToken = atob(params.get('access_token').split('.')[1]);
			sessionStorage.setItem('token', params.get('access_token'));
			sessionStorage.setItem('tokenExp', JSON.parse(accessToken).exp);
			sessionStorage.setItem('username', JSON.parse(accessToken).sub);
			sessionStorage.setItem('country', JSON.parse(accessToken).country);
			sessionStorage.setItem('language', 'pt');

		}

		if (typeof sessionStorage['redirectURL'] !== 'undefined') {
			window.location.href = sessionStorage['redirectURL'];
		}
	}
}
