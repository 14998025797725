import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorHandlerInterceptorService} from '../interceptors/error-handler-interceptor.service';
import {LoaderInterceptorService} from '../interceptors/loader-interceptor.service';
import {TokenInterceptor} from '../interceptors/token-interceptor.service';

export const httpInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptorService, multi: true }
];
