export const environment = {
	production: false,
	environment: 'qa',

	bffUrl: 'https://api.qa01e.gcp.ford.com/vector/bff',
	apsUrl: 'https://wwwedu.accessmgmt.ford.com',

	base64: 'MjcwMWQzMTktZjQyZi00ODViLTgzN2QtOWNmZWNlNWViZWRmOjlkYzA5ZjJhLWRjNjAtNDI4NS1hYjYxLTYyYWEwZDM2ZTA2Ng==',
	oauthUrl: 'https://fd-usr-sso.login.sys.pp01.edc1.cf.ford.com/oauth/token',
	pathToRedirect: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize?' +
		'response_type=token+id_token&' +
		'client_id=urn:vector:pcf:native:qa&' +
		'resource=urn:vector:pcf:weblogin:qa&' +
		'redirect_uri=https://wwwqa.vector.ford.com/oauth-callback',
	redirect: 'https://wwwqa.vector.ford.com/home',
	endpoint: 'https://wwwqa.vector.ford.com/',

	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	openidClientId: 'urn:vector:pcf:native:qa',
	openidResource: 'urn:vector:pcf:weblogin:qa',
	openidRedirectUrl: 'https://wwwqa.vector.ford.com/oauth-callback'
};
