import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OAuthCallbackComponent } from './login/oauth-callback.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardFn } from './core/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		canActivate: [AuthGuardFn],
		canActivateChild: [AuthGuardFn],
		loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
	},
	{
		path: 'oauth-callback',
		component: OAuthCallbackComponent
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'home',
		canActivate: [AuthGuardFn],
		canActivateChild: [AuthGuardFn],
		loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
	},
	{
		path: 'starter',
		canActivate: [AuthGuardFn],
		canActivateChild: [AuthGuardFn],
		loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
	},
	{
		path: '**',
		redirectTo: '/denied',
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
